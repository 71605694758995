<template>
  <b-sidebar
    :width="$vuetify.breakpoint.xsOnly ? '320px' : '420px'"
    right
    backdrop
    shadow
    z-index="2222"
    v-model="exportSidebar"
    header-class="pa-0"
    body-class="h-100"
    id="export-sidebar-guide"
  >
    <template v-slot:header>
      <v-tabs v-model="tabs" fixed-tabs color="#7E04B7" mobile-breakpoint="300">
        <v-tabs-slider
          class="min-w-150px min-w-sm-200px"
          color="#7E04B7"
        ></v-tabs-slider>

        <v-tab>
          <h3 class="font-weight-bolder m-0 font-size-h6 poppins">Columns</h3>
        </v-tab>
        <v-tab v-if="filters.length > 0">
          <h3 class="font-weight-bolder m-0 font-size-h6 poppins">
            Filter
          </h3></v-tab
        >
      </v-tabs>
    </template>

    <template v-slot:default="{ hide }">
      <v-tabs-items v-model="tabs" class="h-100 overflow-y-auto">
        <v-tab-item>
          <v-card flat>
            <v-card-text class="px-0 h-100">
              <Export
                ref="export"
                :headers="exportHeaders"
                :downloadItem="downloadItem"
              ></Export>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item class="h-100" v-if="filters.length > 0">
          <v-card flat>
            <v-card-text class="px-0">
              <FilterContainer
                :key="1"
                :submitFilterValues="submitFilterValues"
                :resetFilterValues="resetFilterValues"
                :filters="filters"
                :buttonTitle="'Search'"
                :setTableFiltersValues="setTableFiltersValues"
                @submit="hide"
              ></FilterContainer>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </b-sidebar>
</template>

<script>
import FilterContainer from "@/own/components/filter/FilterContainer";
import Export from "@/own/components/Export.vue";

export default {
  name: "ExportSidebar",
  components: {
    FilterContainer,
    Export,
  },
  props: [
    "exportHeaders",
    "downloadItem",
    "submitFilterValues",
    "resetFilterValues",
    "filters",
    "setTableFiltersValues",
  ],
  data: () => ({
    exportSidebar: false,
    tabs: null,
  }),
  watch: {
    exportSidebar: function(newValue) {
      newValue === true
        ? document.body.classList.add("overflow-hidden")
        : document.body.classList.remove("overflow-hidden");
    },
  },
};
</script>
